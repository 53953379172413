.splitting .char,
.splitting .word {
    display: inline-block
}

.splitting .char {
    position: relative
}

  
.splitting .char:after,
.splitting .char:before {
    content: attr(data-char);
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    transition: inherit;
    user-select: none
}

.splitting {
    --word-center: calc(var(--word-total)/2 - 0.5);
    --char-center: calc(var(--char-total)/2 - 0.5);
    --line-center: calc(var(--line-total)/2 - 0.5)
}

.splitting .word {
    --word-percent: calc(var(--word-index)/var(--word-total));
    --line-percent: calc(var(--line-index)/var(--line-total))
}

.splitting .char {
    --char-percent: calc(var(--char-index)/var(--char-total));
    --char-offset: calc(var(--char-index) - var(--char-center));
    --distance: calc(var(--char-offset)*var(--char-offset)/var(--char-center));
    --distance-sine: calc(var(--char-offset)/var(--char-center));
    --distance-percent: calc(var(--distance)/var(--char-center))
}

.splitting.cells img {
    width: 100%;
    display: block
}

@supports (display:grid) {
    .splitting.cells {
        position: relative;
        overflow: hidden;
        background-size: cover;
        visibility: hidden
    }

    .splitting .cell-grid {
        background: inherit;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template: repeat(var(--row-total), 1fr)/repeat(var(--col-total), 1fr)
    }

    .splitting .cell {
        background: inherit;
        position: relative;
        overflow: hidden
    }

    .splitting .cell-inner {
        background: inherit;
        position: absolute;
        visibility: visible;
        width: calc(100%*var(--col-total));
        height: calc(100%*var(--row-total));
        left: calc(-100%*var(--col-index));
        top: calc(-100%*var(--row-index))
    }

    .splitting .cell {
        --center-x: calc(var(--col-total)/2 - 0.5);
        --center-y: calc(var(--row-total)/2 - 0.5);
        --offset-x: calc(var(--col-index) - var(--center-x));
        --offset-y: calc(var(--row-index) - var(--center-y));
        --distance-x: calc(var(--offset-x)*var(--offset-x)/var(--center-x));
        --distance-y: calc(var(--offset-y)*var(--offset-y)/var(--center-y))
    }
}

*,
:after,
:before {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none
}

address {
    font-style: normal;
    line-height: inherit
}

address,
dl,
ol,
ul {
    margin-bottom: 1rem
}

dl,
ol,
ul {
    margin-top: 0
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent
}

a:hover {
    color: #0056b3;
    text-decoration: underline
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar
}

figure {
    margin: 0 0 1rem
}

img {
    border-style: none
}

img,
svg {
    vertical-align: middle
}

svg {
    overflow: hidden
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

[type=button],
[type=reset],
[type=submit],


[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}


[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
table td,
table th {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2
}

.h1,
h1 {
    font-size: 2.5rem
}

.h2,
h2 {
    font-size: 2rem
}

.h3,
h3,
table th {
    font-size: 1.75rem
}

.h4,
h4,
table td {
    font-size: 1.5rem
}

.h5,
h5 {
    font-size: 1.25rem
}

.h6,
h6 {
    font-size: 1rem
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

.display-1 {
    font-size: 6rem
}

.display-1,
.display-2 {
    font-weight: 300;
    line-height: 1.2
}

.display-2 {
    font-size: 5.5rem
}

.display-3 {
    font-size: 4.5rem
}

.display-3,
.display-4 {
    font-weight: 300;
    line-height: 1.2
}

.display-4 {
    font-size: 3.5rem
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1)
}

.small,
small {
    font-size: 80%;
    font-weight: 400
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-inline,
.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d
}

.blockquote-footer:before {
    content: "â€”Â "
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media(min-width:576px) {

    .container,
    .container-sm {
        max-width: 758px
    }
}

@media(min-width:768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 90%
    }
}

@media(min-width:992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 90%
    }
}

@media(min-width:1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 80%
    }
}

@media(min-width:1600px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 80%
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto,
.col-xxl,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.row-cols-1>* {
    flex: 0 0 100%;
    max-width: 100%
}

.row-cols-2>* {
    flex: 0 0 50%;
    max-width: 50%
}

.row-cols-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
}

.row-cols-4>* {
    flex: 0 0 25%;
    max-width: 25%
}

.row-cols-5>* {
    flex: 0 0 20%;
    max-width: 20%
}

.row-cols-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
}

.col-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%
}

.col-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%
}

.col-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%
}

.col-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%
}

.col-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    order: -1
}

.order-last {
    order: 13
}

.order-0 {
    order: 0
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.order-6 {
    order: 6
}

.order-7 {
    order: 7
}

.order-8 {
    order: 8
}

.order-9 {
    order: 9
}

.order-10 {
    order: 10
}

.order-11 {
    order: 11
}

.order-12 {
    order: 12
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

@media(min-width:576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-sm-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-sm-1 {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%
    }

    .col-sm-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%
    }

    .col-sm-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%
    }

    .col-sm-8 {
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%
    }

    .col-sm-11 {
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        order: -1
    }

    .order-sm-last {
        order: 13
    }

    .order-sm-0 {
        order: 0
    }

    .order-sm-1 {
        order: 1
    }

    .order-sm-2 {
        order: 2
    }

    .order-sm-3 {
        order: 3
    }

    .order-sm-4 {
        order: 4
    }

    .order-sm-5 {
        order: 5
    }

    .order-sm-6 {
        order: 6
    }

    .order-sm-7 {
        order: 7
    }

    .order-sm-8 {
        order: 8
    }

    .order-sm-9 {
        order: 9
    }

    .order-sm-10 {
        order: 10
    }

    .order-sm-11 {
        order: 11
    }

    .order-sm-12 {
        order: 12
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333333%
    }

    .offset-sm-2 {
        margin-left: 16.66666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333333%
    }

    .offset-sm-5 {
        margin-left: 41.66666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333333%
    }

    .offset-sm-8 {
        margin-left: 66.66666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333333%
    }

    .offset-sm-11 {
        margin-left: 91.66666667%
    }
}

@media(min-width:768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-md-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .row-cols-md-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-md-1 {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%
    }

    .col-md-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%
    }

    .col-md-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%
    }

    .col-md-8 {
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%
    }

    .col-md-11 {
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-md-first {
        order: -1
    }

    .order-md-last {
        order: 13
    }

    .order-md-0 {
        order: 0
    }

    .order-md-1 {
        order: 1
    }

    .order-md-2 {
        order: 2
    }

    .order-md-3 {
        order: 3
    }

    .order-md-4 {
        order: 4
    }

    .order-md-5 {
        order: 5
    }

    .order-md-6 {
        order: 6
    }

    .order-md-7 {
        order: 7
    }

    .order-md-8 {
        order: 8
    }

    .order-md-9 {
        order: 9
    }

    .order-md-10 {
        order: 10
    }

    .order-md-11 {
        order: 11
    }

    .order-md-12 {
        order: 12
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }
}

@media(min-width:992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-lg-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-lg-1 {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%
    }

    .col-lg-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%
    }

    .col-lg-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%
    }

    .col-lg-8 {
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%
    }

    .col-lg-11 {
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-lg-first {
        order: -1
    }

    .order-lg-last {
        order: 13
    }

    .order-lg-0 {
        order: 0
    }

    .order-lg-1 {
        order: 1
    }

    .order-lg-2 {
        order: 2
    }

    .order-lg-3 {
        order: 3
    }

    .order-lg-4 {
        order: 4
    }

    .order-lg-5 {
        order: 5
    }

    .order-lg-6 {
        order: 6
    }

    .order-lg-7 {
        order: 7
    }

    .order-lg-8 {
        order: 8
    }

    .order-lg-9 {
        order: 9
    }

    .order-lg-10 {
        order: 10
    }

    .order-lg-11 {
        order: 11
    }

    .order-lg-12 {
        order: 12
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }
}

@media(min-width:1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-xl-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-xl-1 {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%
    }

    .col-xl-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%
    }

    .col-xl-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%
    }

    .col-xl-8 {
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%
    }

    .col-xl-11 {
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xl-first {
        order: -1
    }

    .order-xl-last {
        order: 13
    }

    .order-xl-0 {
        order: 0
    }

    .order-xl-1 {
        order: 1
    }

    .order-xl-2 {
        order: 2
    }

    .order-xl-3 {
        order: 3
    }

    .order-xl-4 {
        order: 4
    }

    .order-xl-5 {
        order: 5
    }

    .order-xl-6 {
        order: 6
    }

    .order-xl-7 {
        order: 7
    }

    .order-xl-8 {
        order: 8
    }

    .order-xl-9 {
        order: 9
    }

    .order-xl-10 {
        order: 10
    }

    .order-xl-11 {
        order: 11
    }

    .order-xl-12 {
        order: 12
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }
}

@media(min-width:1600px) {
    .col-xxl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-xxl-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-xxl-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-xxl-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .row-cols-xxl-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-xxl-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-xxl-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-xxl-1 {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%
    }

    .col-xxl-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%
    }

    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xxl-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%
    }

    .col-xxl-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%
    }

    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xxl-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%
    }

    .col-xxl-8 {
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%
    }

    .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xxl-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%
    }

    .col-xxl-11 {
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%
    }

    .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xxl-first {
        order: -1
    }

    .order-xxl-last {
        order: 13
    }

    .order-xxl-0 {
        order: 0
    }

    .order-xxl-1 {
        order: 1
    }

    .order-xxl-2 {
        order: 2
    }

    .order-xxl-3 {
        order: 3
    }

    .order-xxl-4 {
        order: 4
    }

    .order-xxl-5 {
        order: 5
    }

    .order-xxl-6 {
        order: 6
    }

    .order-xxl-7 {
        order: 7
    }

    .order-xxl-8 {
        order: 8
    }

    .order-xxl-9 {
        order: 9
    }

    .order-xxl-10 {
        order: 10
    }

    .order-xxl-11 {
        order: 11
    }

    .order-xxl-12 {
        order: 12
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%
    }
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.bg-primary {
    background-color: #007bff !important
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #0062cc !important
}

.bg-secondary {
    background-color: #6c757d !important
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #545b62 !important
}

.bg-success {
    background-color: #28a745 !important
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #1e7e34 !important
}

.bg-info {
    background-color: #17a2b8 !important
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #117a8b !important
}

.bg-warning {
    background-color: #ffc107 !important
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #d39e00 !important
}

.bg-danger {
    background-color: #dc3545 !important
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #bd2130 !important
}

.bg-light {
    background-color: #f8f9fa !important
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #dae0e5 !important
}

.bg-dark {
    background-color: #343a40 !important
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #1d2124 !important
}

.bg-white {
    background-color: #fff !important
}

.bg-transparent {
    background-color: transparent !important
}

.border {
    border: 1px solid #dee2e6 !important
}

.border-top {
    border-top: 1px solid #dee2e6 !important
}

.border-right {
    border-right: 1px solid #dee2e6 !important
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important
}

.border-left {
    border-left: 1px solid #dee2e6 !important
}

.border-0 {
    border: 0 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-right-0 {
    border-right: 0 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-left-0 {
    border-left: 0 !important
}

.border-primary {
    border-color: #007bff !important
}

.border-secondary {
    border-color: #6c757d !important
}

.border-success {
    border-color: #28a745 !important
}

.border-info {
    border-color: #17a2b8 !important
}

.border-warning {
    border-color: #ffc107 !important
}

.border-danger {
    border-color: #dc3545 !important
}

.border-light {
    border-color: #f8f9fa !important
}

.border-dark {
    border-color: #343a40 !important
}

.border-white {
    border-color: #fff !important
}

.rounded-sm {
    border-radius: .2rem !important
}

.rounded {
    border-radius: .25rem !important
}

.rounded-top {
    border-top-left-radius: .25rem !important
}

.rounded-right,
.rounded-top {
    border-top-right-radius: .25rem !important
}

.rounded-bottom,
.rounded-right {
    border-bottom-right-radius: .25rem !important
}

.rounded-bottom,
.rounded-left {
    border-bottom-left-radius: .25rem !important
}

.rounded-left {
    border-top-left-radius: .25rem !important
}

.rounded-lg {
    border-radius: .3rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: 50rem !important
}

.rounded-0 {
    border-radius: 0 !important
}

.clearfix:after {
    display: block;
    clear: both;
    content: ""
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

@media(min-width:576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }
}

@media(min-width:768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }
}

@media(min-width:992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }
}

@media(min-width:1200px) {
    .d-xl-none {
        display: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }
}

@media(min-width:1600px) {
    .d-xxl-none {
        display: none !important
    }

    .d-xxl-inline {
        display: inline !important
    }

    .d-xxl-inline-block {
        display: inline-block !important
    }

    .d-xxl-block {
        display: block !important
    }

    .d-xxl-table {
        display: table !important
    }

    .d-xxl-table-row {
        display: table-row !important
    }

    .d-xxl-table-cell {
        display: table-cell !important
    }

    .d-xxl-flex {
        display: flex !important
    }

    .d-xxl-inline-flex {
        display: inline-flex !important
    }
}

@media print {
    .d-print-none {
        display: none !important
    }

    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.embed-responsive:before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9:before {
    padding-top: 42.85714286%
}

.embed-responsive-16by9:before {
    padding-top: 56.25%
}

.embed-responsive-4by3:before {
    padding-top: 75%
}

.embed-responsive-1by1:before {
    padding-top: 100%
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

@media(min-width:576px) {
    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }
}

@media(min-width:768px) {
    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }
}

@media(min-width:992px) {
    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }
}

@media(min-width:1200px) {
    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }
}

@media(min-width:1600px) {
    .flex-xxl-row {
        flex-direction: row !important
    }

    .flex-xxl-column {
        flex-direction: column !important
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xxl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-xxl-fill {
        flex: 1 1 auto !important
    }

    .flex-xxl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xxl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-xxl-start {
        justify-content: flex-start !important
    }

    .justify-content-xxl-end {
        justify-content: flex-end !important
    }

    .justify-content-xxl-center {
        justify-content: center !important
    }

    .justify-content-xxl-between {
        justify-content: space-between !important
    }

    .justify-content-xxl-around {
        justify-content: space-around !important
    }

    .align-items-xxl-start {
        align-items: flex-start !important
    }

    .align-items-xxl-end {
        align-items: flex-end !important
    }

    .align-items-xxl-center {
        align-items: center !important
    }

    .align-items-xxl-baseline {
        align-items: baseline !important
    }

    .align-items-xxl-stretch {
        align-items: stretch !important
    }

    .align-content-xxl-start {
        align-content: flex-start !important
    }

    .align-content-xxl-end {
        align-content: flex-end !important
    }

    .align-content-xxl-center {
        align-content: center !important
    }

    .align-content-xxl-between {
        align-content: space-between !important
    }

    .align-content-xxl-around {
        align-content: space-around !important
    }

    .align-content-xxl-stretch {
        align-content: stretch !important
    }

    .align-self-xxl-auto {
        align-self: auto !important
    }

    .align-self-xxl-start {
        align-self: flex-start !important
    }

    .align-self-xxl-end {
        align-self: flex-end !important
    }

    .align-self-xxl-center {
        align-self: center !important
    }

    .align-self-xxl-baseline {
        align-self: baseline !important
    }

    .align-self-xxl-stretch {
        align-self: stretch !important
    }
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

@media(min-width:576px) {
    .float-sm-left {
        float: left !important
    }

    .float-sm-right {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }
}

@media(min-width:768px) {
    .float-md-left {
        float: left !important
    }

    .float-md-right {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }
}

@media(min-width:992px) {
    .float-lg-left {
        float: left !important
    }

    .float-lg-right {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }
}

@media(min-width:1200px) {
    .float-xl-left {
        float: left !important
    }

    .float-xl-right {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }
}

@media(min-width:1600px) {
    .float-xxl-left {
        float: left !important
    }

    .float-xxl-right {
        float: right !important
    }

    .float-xxl-none {
        float: none !important
    }
}

.user-select-all {
    user-select: all !important
}

.user-select-auto {
    user-select: auto !important
}

.user-select-none {
    user-select: none !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: sticky !important
}

.fixed-top {
    top: 0
}

.fixed-bottom,
.fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    bottom: 0
}

@supports(position:sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
    box-shadow: none !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.mh-100 {
    max-height: 100% !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.vw-100 {
    width: 100vw !important
}

.vh-100 {
    height: 100vh !important
}

.m-0 {
    margin: 0 !important
}

.mt-0,
.my-0 {
    margin-top: 0 !important
}

.mr-0,
.mx-0 {
    margin-right: 0 !important
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
    margin-left: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.mt-1,
.my-1 {
    margin-top: .25rem !important
}

.mr-1,
.mx-1 {
    margin-right: .25rem !important
}

.mb-1,
.my-1 {
    margin-bottom: .25rem !important
}

.ml-1,
.mx-1 {
    margin-left: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.mt-2,
.my-2 {
    margin-top: .5rem !important
}

.mr-2,
.mx-2 {
    margin-right: .5rem !important
}

.mb-2,
.my-2 {
    margin-bottom: .5rem !important
}

.ml-2,
.mx-2 {
    margin-left: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.mt-3,
.my-3 {
    margin-top: 1rem !important
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.mt-5,
.my-5 {
    margin-top: 3rem !important
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important
}

.p-0 {
    padding: 0 !important
}

.pt-0,
.py-0 {
    padding-top: 0 !important
}

.pr-0,
.px-0 {
    padding-right: 0 !important
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important
}

.pl-0,
.px-0 {
    padding-left: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.pt-1,
.py-1 {
    padding-top: .25rem !important
}

.pr-1,
.px-1 {
    padding-right: .25rem !important
}

.pb-1,
.py-1 {
    padding-bottom: .25rem !important
}

.pl-1,
.px-1 {
    padding-left: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.pt-2,
.py-2 {
    padding-top: .5rem !important
}

.pr-2,
.px-2 {
    padding-right: .5rem !important
}

.pb-2,
.py-2 {
    padding-bottom: .5rem !important
}

.pl-2,
.px-2 {
    padding-left: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.pt-3,
.py-3 {
    padding-top: 1rem !important
}

.pr-3,
.px-3 {
    padding-right: 1rem !important
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important
}

.pl-3,
.px-3 {
    padding-left: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.pt-5,
.py-5 {
    padding-top: 3rem !important
}

.pr-5,
.px-5 {
    padding-right: 3rem !important
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important
}

.pl-5,
.px-5 {
    padding-left: 3rem !important
}

.m-n1 {
    margin: -.25rem !important
}

.mt-n1,
.my-n1 {
    margin-top: -.25rem !important
}

.mr-n1,
.mx-n1 {
    margin-right: -.25rem !important
}

.mb-n1,
.my-n1 {
    margin-bottom: -.25rem !important
}

.ml-n1,
.mx-n1 {
    margin-left: -.25rem !important
}

.m-n2 {
    margin: -.5rem !important
}

.mt-n2,
.my-n2 {
    margin-top: -.5rem !important
}

.mr-n2,
.mx-n2 {
    margin-right: -.5rem !important
}

.mb-n2,
.my-n2 {
    margin-bottom: -.5rem !important
}

.ml-n2,
.mx-n2 {
    margin-left: -.5rem !important
}

.m-n3 {
    margin: -1rem !important
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important
}

.m-n4 {
    margin: -1.5rem !important
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important
}

.m-n5 {
    margin: -3rem !important
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important
}

.m-auto {
    margin: auto !important
}

.mt-auto,
.my-auto {
    margin-top: auto !important
}

.mr-auto,
.mx-auto {
    margin-right: auto !important
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
    margin-left: auto !important
}

@media(min-width:576px) {
    .m-sm-0 {
        margin: 0 !important
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: .25rem !important
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: .25rem !important
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: .25rem !important
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: .5rem !important
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: .5rem !important
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: .5rem !important
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important
    }

    .m-sm-5 {
        margin: 3rem !important
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: .25rem !important
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: .25rem !important
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: .25rem !important
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: .5rem !important
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: .5rem !important
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: .5rem !important
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important
    }

    .p-sm-5 {
        padding: 3rem !important
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important
    }

    .m-sm-n1 {
        margin: -.25rem !important
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -.25rem !important
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -.25rem !important
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -.25rem !important
    }

    .m-sm-n2 {
        margin: -.5rem !important
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -.5rem !important
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -.5rem !important
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -.5rem !important
    }

    .m-sm-n3 {
        margin: -1rem !important
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important
    }

    .m-sm-n4 {
        margin: -1.5rem !important
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important
    }

    .m-sm-n5 {
        margin: -3rem !important
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important
    }
}

@media(min-width:768px) {
    .m-md-0 {
        margin: 0 !important
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: .25rem !important
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: .25rem !important
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: .25rem !important
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: .5rem !important
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: .5rem !important
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: .5rem !important
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important
    }

    .m-md-5 {
        margin: 3rem !important
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: .25rem !important
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: .25rem !important
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: .25rem !important
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: .5rem !important
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: .5rem !important
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: .5rem !important
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important
    }

    .p-md-5 {
        padding: 3rem !important
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important
    }

    .m-md-n1 {
        margin: -.25rem !important
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -.25rem !important
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -.25rem !important
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -.25rem !important
    }

    .m-md-n2 {
        margin: -.5rem !important
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -.5rem !important
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -.5rem !important
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -.5rem !important
    }

    .m-md-n3 {
        margin: -1rem !important
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important
    }

    .m-md-n4 {
        margin: -1.5rem !important
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important
    }

    .m-md-n5 {
        margin: -3rem !important
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important
    }
}

@media(min-width:992px) {
    .m-lg-0 {
        margin: 0 !important
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: .25rem !important
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: .25rem !important
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: .25rem !important
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: .5rem !important
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: .5rem !important
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: .5rem !important
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important
    }

    .m-lg-5 {
        margin: 3rem !important
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: .25rem !important
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: .25rem !important
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: .25rem !important
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: .5rem !important
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: .5rem !important
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: .5rem !important
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important
    }

    .p-lg-5 {
        padding: 3rem !important
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important
    }

    .m-lg-n1 {
        margin: -.25rem !important
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -.25rem !important
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -.25rem !important
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -.25rem !important
    }

    .m-lg-n2 {
        margin: -.5rem !important
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -.5rem !important
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -.5rem !important
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -.5rem !important
    }

    .m-lg-n3 {
        margin: -1rem !important
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important
    }

    .m-lg-n4 {
        margin: -1.5rem !important
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important
    }

    .m-lg-n5 {
        margin: -3rem !important
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important
    }
}

@media(min-width:1200px) {
    .m-xl-0 {
        margin: 0 !important
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: .25rem !important
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: .25rem !important
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: .25rem !important
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: .5rem !important
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: .5rem !important
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: .5rem !important
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important
    }

    .m-xl-5 {
        margin: 3rem !important
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: .25rem !important
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: .25rem !important
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: .25rem !important
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: .5rem !important
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: .5rem !important
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: .5rem !important
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important
    }

    .p-xl-5 {
        padding: 3rem !important
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important
    }

    .m-xl-n1 {
        margin: -.25rem !important
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -.25rem !important
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -.25rem !important
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -.25rem !important
    }

    .m-xl-n2 {
        margin: -.5rem !important
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -.5rem !important
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -.5rem !important
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -.5rem !important
    }

    .m-xl-n3 {
        margin: -1rem !important
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important
    }

    .m-xl-n4 {
        margin: -1.5rem !important
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important
    }

    .m-xl-n5 {
        margin: -3rem !important
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important
    }
}

@media(min-width:1600px) {
    .m-xxl-0 {
        margin: 0 !important
    }

    .mt-xxl-0,
    .my-xxl-0 {
        margin-top: 0 !important
    }

    .mr-xxl-0,
    .mx-xxl-0 {
        margin-right: 0 !important
    }

    .mb-xxl-0,
    .my-xxl-0 {
        margin-bottom: 0 !important
    }

    .ml-xxl-0,
    .mx-xxl-0 {
        margin-left: 0 !important
    }

    .m-xxl-1 {
        margin: .25rem !important
    }

    .mt-xxl-1,
    .my-xxl-1 {
        margin-top: .25rem !important
    }

    .mr-xxl-1,
    .mx-xxl-1 {
        margin-right: .25rem !important
    }

    .mb-xxl-1,
    .my-xxl-1 {
        margin-bottom: .25rem !important
    }

    .ml-xxl-1,
    .mx-xxl-1 {
        margin-left: .25rem !important
    }

    .m-xxl-2 {
        margin: .5rem !important
    }

    .mt-xxl-2,
    .my-xxl-2 {
        margin-top: .5rem !important
    }

    .mr-xxl-2,
    .mx-xxl-2 {
        margin-right: .5rem !important
    }

    .mb-xxl-2,
    .my-xxl-2 {
        margin-bottom: .5rem !important
    }

    .ml-xxl-2,
    .mx-xxl-2 {
        margin-left: .5rem !important
    }

    .m-xxl-3 {
        margin: 1rem !important
    }

    .mt-xxl-3,
    .my-xxl-3 {
        margin-top: 1rem !important
    }

    .mr-xxl-3,
    .mx-xxl-3 {
        margin-right: 1rem !important
    }

    .mb-xxl-3,
    .my-xxl-3 {
        margin-bottom: 1rem !important
    }

    .ml-xxl-3,
    .mx-xxl-3 {
        margin-left: 1rem !important
    }

    .m-xxl-4 {
        margin: 1.5rem !important
    }

    .mt-xxl-4,
    .my-xxl-4 {
        margin-top: 1.5rem !important
    }

    .mr-xxl-4,
    .mx-xxl-4 {
        margin-right: 1.5rem !important
    }

    .mb-xxl-4,
    .my-xxl-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-xxl-4,
    .mx-xxl-4 {
        margin-left: 1.5rem !important
    }

    .m-xxl-5 {
        margin: 3rem !important
    }

    .mt-xxl-5,
    .my-xxl-5 {
        margin-top: 3rem !important
    }

    .mr-xxl-5,
    .mx-xxl-5 {
        margin-right: 3rem !important
    }

    .mb-xxl-5,
    .my-xxl-5 {
        margin-bottom: 3rem !important
    }

    .ml-xxl-5,
    .mx-xxl-5 {
        margin-left: 3rem !important
    }

    .p-xxl-0 {
        padding: 0 !important
    }

    .pt-xxl-0,
    .py-xxl-0 {
        padding-top: 0 !important
    }

    .pr-xxl-0,
    .px-xxl-0 {
        padding-right: 0 !important
    }

    .pb-xxl-0,
    .py-xxl-0 {
        padding-bottom: 0 !important
    }

    .pl-xxl-0,
    .px-xxl-0 {
        padding-left: 0 !important
    }

    .p-xxl-1 {
        padding: .25rem !important
    }

    .pt-xxl-1,
    .py-xxl-1 {
        padding-top: .25rem !important
    }

    .pr-xxl-1,
    .px-xxl-1 {
        padding-right: .25rem !important
    }

    .pb-xxl-1,
    .py-xxl-1 {
        padding-bottom: .25rem !important
    }

    .pl-xxl-1,
    .px-xxl-1 {
        padding-left: .25rem !important
    }

    .p-xxl-2 {
        padding: .5rem !important
    }

    .pt-xxl-2,
    .py-xxl-2 {
        padding-top: .5rem !important
    }

    .pr-xxl-2,
    .px-xxl-2 {
        padding-right: .5rem !important
    }

    .pb-xxl-2,
    .py-xxl-2 {
        padding-bottom: .5rem !important
    }

    .pl-xxl-2,
    .px-xxl-2 {
        padding-left: .5rem !important
    }

    .p-xxl-3 {
        padding: 1rem !important
    }

    .pt-xxl-3,
    .py-xxl-3 {
        padding-top: 1rem !important
    }

    .pr-xxl-3,
    .px-xxl-3 {
        padding-right: 1rem !important
    }

    .pb-xxl-3,
    .py-xxl-3 {
        padding-bottom: 1rem !important
    }

    .pl-xxl-3,
    .px-xxl-3 {
        padding-left: 1rem !important
    }

    .p-xxl-4 {
        padding: 1.5rem !important
    }

    .pt-xxl-4,
    .py-xxl-4 {
        padding-top: 1.5rem !important
    }

    .pr-xxl-4,
    .px-xxl-4 {
        padding-right: 1.5rem !important
    }

    .pb-xxl-4,
    .py-xxl-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-xxl-4,
    .px-xxl-4 {
        padding-left: 1.5rem !important
    }

    .p-xxl-5 {
        padding: 3rem !important
    }

    .pt-xxl-5,
    .py-xxl-5 {
        padding-top: 3rem !important
    }

    .pr-xxl-5,
    .px-xxl-5 {
        padding-right: 3rem !important
    }

    .pb-xxl-5,
    .py-xxl-5 {
        padding-bottom: 3rem !important
    }

    .pl-xxl-5,
    .px-xxl-5 {
        padding-left: 3rem !important
    }

    .m-xxl-n1 {
        margin: -.25rem !important
    }

    .mt-xxl-n1,
    .my-xxl-n1 {
        margin-top: -.25rem !important
    }

    .mr-xxl-n1,
    .mx-xxl-n1 {
        margin-right: -.25rem !important
    }

    .mb-xxl-n1,
    .my-xxl-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-xxl-n1,
    .mx-xxl-n1 {
        margin-left: -.25rem !important
    }

    .m-xxl-n2 {
        margin: -.5rem !important
    }

    .mt-xxl-n2,
    .my-xxl-n2 {
        margin-top: -.5rem !important
    }

    .mr-xxl-n2,
    .mx-xxl-n2 {
        margin-right: -.5rem !important
    }

    .mb-xxl-n2,
    .my-xxl-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-xxl-n2,
    .mx-xxl-n2 {
        margin-left: -.5rem !important
    }

    .m-xxl-n3 {
        margin: -1rem !important
    }

    .mt-xxl-n3,
    .my-xxl-n3 {
        margin-top: -1rem !important
    }

    .mr-xxl-n3,
    .mx-xxl-n3 {
        margin-right: -1rem !important
    }

    .mb-xxl-n3,
    .my-xxl-n3 {
        margin-bottom: -1rem !important
    }

    .ml-xxl-n3,
    .mx-xxl-n3 {
        margin-left: -1rem !important
    }

    .m-xxl-n4 {
        margin: -1.5rem !important
    }

    .mt-xxl-n4,
    .my-xxl-n4 {
        margin-top: -1.5rem !important
    }

    .mr-xxl-n4,
    .mx-xxl-n4 {
        margin-right: -1.5rem !important
    }

    .mb-xxl-n4,
    .my-xxl-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-xxl-n4,
    .mx-xxl-n4 {
        margin-left: -1.5rem !important
    }

    .m-xxl-n5 {
        margin: -3rem !important
    }

    .mt-xxl-n5,
    .my-xxl-n5 {
        margin-top: -3rem !important
    }

    .mr-xxl-n5,
    .mx-xxl-n5 {
        margin-right: -3rem !important
    }

    .mb-xxl-n5,
    .my-xxl-n5 {
        margin-bottom: -3rem !important
    }

    .ml-xxl-n5,
    .mx-xxl-n5 {
        margin-left: -3rem !important
    }

    .m-xxl-auto {
        margin: auto !important
    }

    .mt-xxl-auto,
    .my-xxl-auto {
        margin-top: auto !important
    }

    .mr-xxl-auto,
    .mx-xxl-auto {
        margin-right: auto !important
    }

    .mb-xxl-auto,
    .my-xxl-auto {
        margin-bottom: auto !important
    }

    .ml-xxl-auto,
    .mx-xxl-auto {
        margin-left: auto !important
    }
}

.stretched-link:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: transparent
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important
}

.text-justify {
    text-align: justify !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media(min-width:576px) {
    .text-sm-left {
        text-align: left !important
    }

    .text-sm-right {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media(min-width:768px) {
    .text-md-left {
        text-align: left !important
    }

    .text-md-right {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media(min-width:992px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media(min-width:1200px) {
    .text-xl-left {
        text-align: left !important
    }

    .text-xl-right {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

@media(min-width:1600px) {
    .text-xxl-left {
        text-align: left !important
    }

    .text-xxl-right {
        text-align: right !important
    }

    .text-xxl-center {
        text-align: center !important
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-lighter {
    font-weight: lighter !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: 700 !important
}

.font-weight-bolder {
    font-weight: bolder !important
}

.font-italic {
    font-style: italic !important
}

.text-white {
    color: #fff !important
}

.text-primary {
    color: #007bff !important
}

a.text-primary:focus,
a.text-primary:hover {
    color: #0056b3 !important
}

.text-secondary {
    color: #6c757d !important
}

a.text-secondary:focus,
a.text-secondary:hover {
    color: #494f54 !important
}

.text-success {
    color: #28a745 !important
}

a.text-success:focus,
a.text-success:hover {
    color: #19692c !important
}

.text-info {
    color: #17a2b8 !important
}

a.text-info:focus,
a.text-info:hover {
    color: #0f6674 !important
}

.text-warning {
    color: #ffc107 !important
}

a.text-warning:focus,
a.text-warning:hover {
    color: #ba8b00 !important
}

.text-danger {
    color: #dc3545 !important
}

a.text-danger:focus,
a.text-danger:hover {
    color: #a71d2a !important
}

.text-light {
    color: #f8f9fa !important
}

a.text-light:focus,
a.text-light:hover {
    color: #cbd3da !important
}

.text-dark {
    color: #343a40 !important
}

a.text-dark:focus,
a.text-dark:hover {
    color: #121416 !important
}

.text-body {
    color: #212529 !important
}

.text-muted {
    color: #6c757d !important
}

.text-black-50 {
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    color: hsla(0, 0%, 100%, .5) !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.text-decoration-none {
    text-decoration: none !important
}

.text-break {
    word-break: break-word !important;
    word-wrap: break-word !important
}

.text-reset {
    color: inherit !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

body {
    line-height: 1
}

ol,
ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: "";
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

input {
    border-radius: 0
}



body,
html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    color: #fff;
    background-color: #0b0b0b;
    height: 100%;
    width: 100%;
    line-height: 1.4;
    font-family: Apfel Grotezk Regular;
    font-weight: 400;
    font-size: 18px
}

@media(min-width:576px) {

    body,
    html {
        font-size: 18px
    }
}

@media(min-width:768px) {

    body,
    html {
        font-size: 21px
    }
}

@media(min-width:992px) {

    body,
    html {
        font-size: 21px
    }
}

@media(min-width:1200px) {

    body,
    html {
        font-size: 23px
    }
}

@media(min-width:1600px) {

    body,
    html {
        font-size: 23px
    }
}

html {
    overflow-y: scroll;
    overflow-x: hidden
}

main {
    margin-top: 24px
}

@media(min-width:768px) {
    main {
        margin-top: 80px
    }
}

img {
    width: 100%;
    height: auto
}

.flexc_but_img,
[id^=cms_] img,
img[id^=cms_],
img[id^=m] {
    width: auto
}

button {
    background: none;
    color: #fff;
    border: none
}

button:focus {
    outline: none
}

h1,
h2,
h3,
h4,
p {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto
}

.h1,
.h2,
.h3,
.h4,
h1,
h2,
h3,
h4,
table td,
table th {
    line-height: 1.1;
    font-family: Clash Display Semibold;
   
}

.h1,
h1 {
    font-size: 31px
}

@media(min-width:576px) {

    .h1,
    h1 {
        font-size: 31px
    }
}

@media(min-width:768px) {

    .h1,
    h1 {
        font-size: 60px
    }
}

@media(min-width:992px) {

    .h1,
    h1 {
        font-size: 60px
    }
}

@media(min-width:1200px) {

    .h1,
    h1 {
        font-size: 80px
    }
}

@media(min-width:1600px) {

    .h1,
    h1 {
        font-size: 80px
    }
}

.h2,
h2 {
    font-size: 31px;
    letter-spacing: .8px
}

@media(min-width:576px) {

    .h2,
    h2 {
        font-size: 31px
    }
}

@media(min-width:768px) {

    .h2,
    h2 {
        font-size: 42px
    }
}

@media(min-width:992px) {

    .h2,
    h2 {
        font-size: 42px
    }
}

@media(min-width:1200px) {

    .h2,
    h2 {
        font-size: 65px
    }
}

@media(min-width:1600px) {

    .h2,
    h2 {
        font-size: 65px
    }
}

.h3,
h3,
table th {
    font-size: 21px
}

@media(min-width:576px) {

    .h3,
    h3,
    table th {
        font-size: 21px
    }
}

@media(min-width:768px) {

    .h3,
    h3,
    table th {
        font-size: 24px
    }
}

@media(min-width:992px) {

    .h3,
    h3,
    table th {
        font-size: 24px
    }
}

@media(min-width:1200px) {

    .h3,
    h3,
    table th {
        font-size: 28px
    }
}

@media(min-width:1600px) {

    .h3,
    h3,
    table th {
        font-size: 28px
    }
}

.h4,
h4,
table td {
    font-size: 21px;
    font-family: Apfel Grotezk Regular
}

@media(min-width:576px) {

    .h4,
    h4,
    table td {
        font-size: 21px
    }
}

@media(min-width:768px) {

    .h4,
    h4,
    table td {
        font-size: 23px
    }
}

@media(min-width:992px) {

    .h4,
    h4,
    table td {
        font-size: 23px
    }
}

@media(min-width:1200px) {

    .h4,
    h4,
    table td {
        font-size: 30px
    }
}

@media(min-width:1600px) {

    .h4,
    h4,
    table td {
        font-size: 30px
    }
}

.rich-text ol,
.rich-text p,
.rich-text table,
.rich-text ul {
    margin-bottom: 1em
}

.rich-text ol:last-child,
.rich-text p:last-child,
.rich-text table:last-child,
.rich-text ul:last-child {
    margin-bottom: 0
}

:focus {
    outline: none
}

::selection {
    background-color: #fff;
    color: #fff
}

picture {
    display: block;
    line-height: 1;
    pointer-events: none
}

figcaption {
    margin-top: 1em
}

.lead {
    font-size: 1.1rem;
    line-height: 1.2
}

@media(min-width:1200px) {
    .lead {
        font-size: 2rem
    }
}

a {
    position: relative
}

a,
a:hover {
    color: #fff
}

a:hover {
    text-decoration: none
}

.a,
.rich-text a {
    display: inline-block
}

.a:hover:after,
.rich-text a:hover:after {
    transform: scaleX(0)
}

.a:after,
.rich-text a:after {
    transition: transform .5s;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: #fff;
    transform-origin: right;
    transform: scaleX(1)
}

.font-text {
    font-family: Apfel Grotezk Regular
}

.bg-grey {
    background-color: #191919
}

table {
    width: 100%
}

table tr {
    border-bottom: 1px solid #fff
}

@media(max-width:767.98px) {

    table td,
    table th {
        display: block
    }

    table td:first-child,
    table th:first-child {
        padding-top: .6em;
        margin-bottom: .2em
    }

    table td:last-child,
    table th:last-child {
        margin-bottom: 0;
        padding-bottom: .6em
    }
}

@media(min-width:768px) {

    table td,
    table th {
        padding: .6em
    }

    table td:first-child,
    table th:first-child {
        padding-left: 0
    }

    table td:last-child,
    table th:last-child {
        padding-right: 0
    }
}

.transition .preview__image {
    opacity: 0;
    z-index: -1
}

.transition--visible .preview__image {
    opacity: 1;
    z-index: 1
}

.signature {
    max-width: 300px
}

.bg-blue {
    background-color: #007bff
}

.bg-indigo {
    background-color: #6610f2
}

.bg-purple {
    background-color: #6f42c1
}

.bg-pink {
    background-color: #e83e8c
}

.bg-red {
    background-color: #dc3545
}

.bg-orange {
    background-color: #fd7e14
}

.bg-yellow {
    background-color: #ffc107
}

.bg-green {
    background-color: #28a745
}

.bg-teal {
    background-color: #20c997
}

.bg-cyan {
    background-color: #17a2b8
}

.bg-white {
    background-color: #fff
}

.bg-gray {
    background-color: #6c757d
}

.bg-gray-dark {
    background-color: #343a40
}

.responsive {
    display: block;
    width: 100%
}

.no-scroll {
    overflow: hidden
}

.gutter-bottom {
    padding-bottom: 50px
}

@media(min-width:576px) {
    .gutter-bottom {
        padding-bottom: 50px
    }
}

@media(min-width:768px) {
    .gutter-bottom {
        padding-bottom: 100px
    }
}

@media(min-width:992px) {
    .gutter-bottom {
        padding-bottom: 100px
    }
}

@media(min-width:1200px) {
    .gutter-bottom {
        padding-bottom: 150px
    }
}

@media(min-width:1600px) {
    .gutter-bottom {
        padding-bottom: 150px
    }
}

.gutter-bottom-s {
    padding-bottom: 30px
}

@media(min-width:576px) {
    .gutter-bottom-s {
        padding-bottom: 30px
    }
}

@media(min-width:768px) {
    .gutter-bottom-s {
        padding-bottom: 60px
    }
}

@media(min-width:992px) {
    .gutter-bottom-s {
        padding-bottom: 60px
    }
}

@media(min-width:1200px) {
    .gutter-bottom-s {
        padding-bottom: 130px
    }
}

@media(min-width:1600px) {
    .gutter-bottom-s {
        padding-bottom: 130px
    }
}

.gutter-bottom-l {
    padding-bottom: 70px
}

@media(min-width:576px) {
    .gutter-bottom-l {
        padding-bottom: 70px
    }
}

@media(min-width:768px) {
    .gutter-bottom-l {
        padding-bottom: 135px
    }
}

@media(min-width:992px) {
    .gutter-bottom-l {
        padding-bottom: 135px
    }
}

@media(min-width:1200px) {
    .gutter-bottom-l {
        padding-bottom: 225px
    }
}

@media(min-width:1600px) {
    .gutter-bottom-l {
        padding-bottom: 225px
    }
}

.gutter-top {
    padding-top: 50px
}

@media(min-width:576px) {
    .gutter-top {
        padding-top: 50px
    }
}

@media(min-width:768px) {
    .gutter-top {
        padding-top: 100px
    }
}

@media(min-width:992px) {
    .gutter-top {
        padding-top: 100px
    }
}

@media(min-width:1200px) {
    .gutter-top {
        padding-top: 150px
    }
}

@media(min-width:1600px) {
    .gutter-top {
        padding-top: 150px
    }
}

.gutter-top-s {
    padding-top: 30px
}

@media(min-width:576px) {
    .gutter-top-s {
        padding-top: 30px
    }
}

@media(min-width:768px) {
    .gutter-top-s {
        padding-top: 60px
    }
}

@media(min-width:992px) {
    .gutter-top-s {
        padding-top: 60px
    }
}

@media(min-width:1200px) {
    .gutter-top-s {
        padding-top: 130px
    }
}

@media(min-width:1600px) {
    .gutter-top-s {
        padding-top: 130px
    }
}

.gutter-top-l {
    padding-top: 70px
}

@media(min-width:576px) {
    .gutter-top-l {
        padding-top: 70px
    }
}

@media(min-width:768px) {
    .gutter-top-l {
        padding-top: 135px
    }
}

@media(min-width:992px) {
    .gutter-top-l {
        padding-top: 135px
    }
}

@media(min-width:1200px) {
    .gutter-top-l {
        padding-top: 225px
    }
}

@media(min-width:1600px) {
    .gutter-top-l {
        padding-top: 225px
    }
}

.push-bottom {
    margin-bottom: 50px
}

@media(min-width:576px) {
    .push-bottom {
        margin-bottom: 50px
    }
}

@media(min-width:768px) {
    .push-bottom {
        margin-bottom: 100px
    }
}

@media(min-width:992px) {
    .push-bottom {
        margin-bottom: 100px
    }
}

@media(min-width:1200px) {
    .push-bottom {
        margin-bottom: 150px
    }
}

@media(min-width:1600px) {
    .push-bottom {
        margin-bottom: 150px
    }
}

.push-bottom-s {
    margin-bottom: 30px
}

@media(min-width:576px) {
    .push-bottom-s {
        margin-bottom: 30px
    }
}

@media(min-width:768px) {
    .push-bottom-s {
        margin-bottom: 60px
    }
}

@media(min-width:992px) {
    .push-bottom-s {
        margin-bottom: 60px
    }
}

@media(min-width:1200px) {
    .push-bottom-s {
        margin-bottom: 130px
    }
}

@media(min-width:1600px) {
    .push-bottom-s {
        margin-bottom: 130px
    }
}

.push-bottom-l {
    margin-bottom: 70px
}

@media(min-width:576px) {
    .push-bottom-l {
        margin-bottom: 70px
    }
}

@media(min-width:768px) {
    .push-bottom-l {
        margin-bottom: 135px
    }
}

@media(min-width:992px) {
    .push-bottom-l {
        margin-bottom: 135px
    }
}

@media(min-width:1200px) {
    .push-bottom-l {
        margin-bottom: 225px
    }
}

@media(min-width:1600px) {
    .push-bottom-l {
        margin-bottom: 225px
    }
}

.push-top {
    margin-top: 50px
}

@media(min-width:576px) {
    .push-top {
        margin-top: 50px
    }
}

@media(min-width:768px) {
    .push-top {
        margin-top: 100px
    }
}

@media(min-width:992px) {
    .push-top {
        margin-top: 100px
    }
}

@media(min-width:1200px) {
    .push-top {
        margin-top: 150px
    }
}

@media(min-width:1600px) {
    .push-top {
        margin-top: 150px
    }
}

.push-top-s {
    margin-top: 30px
}

@media(min-width:576px) {
    .push-top-s {
        margin-top: 30px
    }
}

@media(min-width:768px) {
    .push-top-s {
        margin-top: 60px
    }
}

@media(min-width:992px) {
    .push-top-s {
        margin-top: 60px
    }
}

@media(min-width:1200px) {
    .push-top-s {
        margin-top: 130px
    }
}

@media(min-width:1600px) {
    .push-top-s {
        margin-top: 130px
    }
}

.push-top-l {
    margin-top: 70px
}

@media(min-width:576px) {
    .push-top-l {
        margin-top: 70px
    }
}

@media(min-width:768px) {
    .push-top-l {
        margin-top: 135px
    }
}

@media(min-width:992px) {
    .push-top-l {
        margin-top: 135px
    }
}

@media(min-width:1200px) {
    .push-top-l {
        margin-top: 225px
    }
}

@media(min-width:1600px) {
    .push-top-l {
        margin-top: 225px
    }
}

.sg__spacing {
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px
}

.sg__spacing--regular {
    height: 50px
}

@media(min-width:576px) {
    .sg__spacing--regular {
        height: 50px
    }
}

@media(min-width:768px) {
    .sg__spacing--regular {
        height: 100px
    }
}

@media(min-width:992px) {
    .sg__spacing--regular {
        height: 100px
    }
}

@media(min-width:1200px) {
    .sg__spacing--regular {
        height: 150px
    }
}

@media(min-width:1600px) {
    .sg__spacing--regular {
        height: 150px
    }
}

.sg__spacing--regular:after {
    content: "Abstand (normal): 50px"
}

@media(min-width:576px) {
    .sg__spacing--regular:after {
        content: "Abstand (normal): 50px"
    }
}

@media(min-width:768px) {
    .sg__spacing--regular:after {
        content: "Abstand (normal): 100px"
    }
}

@media(min-width:992px) {
    .sg__spacing--regular:after {
        content: "Abstand (normal): 100px"
    }
}

@media(min-width:1200px) {
    .sg__spacing--regular:after {
        content: "Abstand (normal): 150px"
    }
}

@media(min-width:1600px) {
    .sg__spacing--regular:after {
        content: "Abstand (normal): 150px"
    }
}

.sg__spacing--small {
    height: 30px
}

@media(min-width:576px) {
    .sg__spacing--small {
        height: 30px
    }
}

@media(min-width:768px) {
    .sg__spacing--small {
        height: 60px
    }
}

@media(min-width:992px) {
    .sg__spacing--small {
        height: 60px
    }
}

@media(min-width:1200px) {
    .sg__spacing--small {
        height: 130px
    }
}

@media(min-width:1600px) {
    .sg__spacing--small {
        height: 130px
    }
}

.sg__spacing--small:after {
    content: "Abstand (klein): 30px"
}

@media(min-width:576px) {
    .sg__spacing--small:after {
        content: "Abstand (klein): 30px"
    }
}

@media(min-width:768px) {
    .sg__spacing--small:after {
        content: "Abstand (klein): 60px"
    }
}

@media(min-width:992px) {
    .sg__spacing--small:after {
        content: "Abstand (klein): 60px"
    }
}

@media(min-width:1200px) {
    .sg__spacing--small:after {
        content: "Abstand (klein): 130px"
    }
}

@media(min-width:1600px) {
    .sg__spacing--small:after {
        content: "Abstand (klein): 130px"
    }
}

.sg__spacing--large {
    height: 70px
}

@media(min-width:576px) {
    .sg__spacing--large {
        height: 70px
    }
}

@media(min-width:768px) {
    .sg__spacing--large {
        height: 135px
    }
}

@media(min-width:992px) {
    .sg__spacing--large {
        height: 135px
    }
}

@media(min-width:1200px) {
    .sg__spacing--large {
        height: 225px
    }
}

@media(min-width:1600px) {
    .sg__spacing--large {
        height: 225px
    }
}

.sg__spacing--large:after {
    content: "Abstand (gross): 70px"
}

@media(min-width:576px) {
    .sg__spacing--large:after {
        content: "Abstand (gross): 70px"
    }
}

@media(min-width:768px) {
    .sg__spacing--large:after {
        content: "Abstand (gross): 135px"
    }
}

@media(min-width:992px) {
    .sg__spacing--large:after {
        content: "Abstand (gross): 135px"
    }
}

@media(min-width:1200px) {
    .sg__spacing--large:after {
        content: "Abstand (gross): 225px"
    }
}

@media(min-width:1600px) {
    .sg__spacing--large:after {
        content: "Abstand (gross): 225px"
    }
}

.sg__color {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh
}

.sg__color-label {
    background-color: inherit;
    display: inline-block;
    padding: 6px;
    color: #000
}

.sg__color--primary {
    background-color: #fff
}

.sg__color--secondary {
    background-color: #0b0b0b
}

.sg__color--action {
    background-color: #fff
}

.header {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 19px 28px;
    background-color: #0b0b0b;
    z-index: 1000;
    width: 100%
}

@media(min-width:768px) {
    .header {
        bottom: auto;
        top: 0
    }
}

[data-fade-in=el] {
    opacity: 0;
    line-height: 1
}

[data-fade-in=el] * {
    line-height: 1
}

[data-fade-in=el].initted {
    opacity: 1
}

[data-fade-in=el] .word {
    position: relative;
    display: inline-block;
    visibility: hidden;
    overflow: hidden
}

[data-fade-in=el] .word:after {
    transition: transform 1s;
    transition-timing-function: cubic-bezier(.1, .25, .3, 1);
    content: attr(data-word);
    position: absolute;
    left: 0;
    bottom: 0;
    visibility: visible;
    transform: rotate(5deg) translateY(110%)
}

[data-fade-in=el] .word--fade-in:after {
    transform: rotate(0) translateY(0)
}

.navigation {
    display: flex;
    justify-content: space-between
}

.navigation__link {
    position: relative;
    display: inline-block
}

.navigation__link:after {
    transition: transform .5s;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: left
}

.navigation__link--active:after,
.navigation__link:hover:after {
    transform: scaleX(1)
}

.showreel {
    position: relative;
    aspect-ratio: 16/9
}

.showreel__image {
    transition: opacity .5s;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1
}

.showreel--loaded .showreel__image {
    opacity: 0;
    pointer-events: none
}

.showreel__audio {
    position: absolute;
    bottom: 12px;
    right: 12px;
    z-index: 10;
    width: 24px;
    padding: 0
}

.showreel__audio-icon--unmute {
    display: block
}

.showreel__audio--unmuted .showreel__audio-icon--unmute,
.showreel__audio-icon--mute {
    display: none
}

.showreel__audio--unmuted .showreel__audio-icon--mute {
    display: block
}

.portrait__title {
    font-size: 45px;
    line-height: 1;
    transform: translateX(-25%);
    width: 140%;
    margin-top: .3em
}

@media(min-width:768px) {
    .portrait__title {
        font-size: 90px
    }
}

@media(min-width:992px) {
    .portrait__title {
        width: 100%;
        transform: translateX(-20%)
    }
}

@media(min-width:1200px) {
    .portrait__title {
        font-size: 110px;
        margin-top: 2em
    }
}

@media(min-width:1600px) {
    .portrait__title {
        font-size: 140px
    }
}

.teaser__info {
    pointer-events: none
}

@media(min-width:768px) {
    .teaser__info {
        position: absolute;
        z-index: 10
    }
}

.teaser__link {
    aspect-ratio: 16/9;
    position: relative;
    display: block;
    cursor: none;
    overflow: hidden
}

.teaser__link:hover .preview__image {
    display: none
}

.teaser--trigger .preview__image {
    display: block !important
}

.cursor {
    position: relative
}

.cursor,
.cursor * {
    cursor: none !important
}

.cursor * {
    pointer-events: none !important
}

.cursor__cursor {
    position: absolute;
    height: auto;
    transform: translate(-50%, -50%) scale(0);
    z-index: 1000;
    pointer-events: none;
    opacity: 0;
    width: 50px
}

@media(min-width:768px) {
    .cursor__cursor {
        width: 100px
    }
}

@media(min-width:1200px) {
    .cursor__cursor {
        width: 125px
    }
}

@media(min-width:1600px) {
    .cursor__cursor {
        width: 150px
    }
}

.cursor--initted .cursor__cursor {
    transition: transform .3s, opacity .3s;
    transition-timing-function: cubic-bezier(.1, .25, .3, 1)
}

.cursor--visible .cursor__cursor {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1
}

.preview__overlay {
    background-color: rgba(11, 11, 11, .2);
    z-index: 9
}

.preview__image,
.preview__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
}

.preview__image {
    z-index: 1
}

.text-animation__text {
    font-size: 37px;
    line-height: none;
    white-space: nowrap;
    color: transparent;
    -webkit-text-stroke: 1px #fff;
    font-family: Clash Display Semibold;
    display: block
}

@media(min-width:768px) {
    .text-animation__text {
        font-size: 60px
    }
}

@media(min-width:992px) {
    .text-animation__text {
        font-size: 100px
    }
}

@media(min-width:1200px) {
    .text-animation__text {
        font-size: 160px;
        font-size: 200px
    }
}

.text-animation__container,
.video {
    overflow: hidden
}

.video {
    aspect-ratio: 16/9;
    position: relative;
    cursor: pointer;
    max-height: calc(100vh - 84px);
    margin: 0 auto
}

.video__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1
}

.video--playing .video__image {
    display: none
}

.video__overlay {
    transition: opacity .3s;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: all !important;
    background-color: rgba(11, 11, 11, .2);
    z-index: 2
}

.video--playing .video__overlay {
    opacity: 0;
    pointer-events: none !important
}

.video__play {
    pointer-events: none;
    opacity: 1;
    transition: opacity .3s;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    width: 34px;
    height: 34px
}

@media(min-width:576px) {
    .video__play {
        width: 50px;
        height: 50px
    }
}

@media(min-width:768px) {
    .video__play {
        width: 90px;
        height: 90px
    }
}

@media(min-width:992px) {
    .video__play {
        width: 130px;
        height: 130px
    }
}

@media(min-width:1200px) {
    .video__play {
        width: 170px;
        height: 170px
    }
}

.video--playing .video__play {
    opacity: 0
}

.next {
    position: relative;
    display: block;
    cursor: pointer;
    z-index: 1
}

.next__image {
    display: flex;
    height: 100%;
    width: 100%;
    opacity: .7
}

.next__image>img {
    object-fit: cover
}

.next__image-container {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none
}

.slider {
    background-color: #0b0b0b;
    overflow: hidden
}

.slider__content {
    position: relative
}

.slider__title {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    z-index: 1;
    display: block;
    white-space: nowrap;
    color: transparent;
    -webkit-text-stroke-color: #fff;
    -webkit-text-stroke-width: 1px;
    transition: color 1s;
    cursor: pointer
}

.slider__title--current {
    left: 50%
}

.slider__title--next {
    left: calc(100% - 24px)
}

.slider__title--prev {
    left: 24px
}

.slider__title--fade-in {
    color: #fff
}

.slider__picture {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0
}

.slider__picture-container {
    position: relative;
    aspect-ratio: 3/2
}

.infinite {
    position: relative;
    overflow: hidden;
    width: 100%
}

.infinite__container .infinite {
    margin-top: -104px
}

.infinite__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh
}

.infinite__title {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    white-space: nowrap;
    z-index: 100;
    color: transparent;
    -webkit-text-stroke-color: #fff;
    -webkit-text-stroke-width: 1px;
    transition: color 1s, font-size 1s;
    cursor: pointer
}

@media(max-width:767.98px) {
    .infinite__title {
        font-size: 20px
    }
}

.infinite__title--current {
    left: 50%;
    pointer-events: none
}

.infinite__title--prev {
    left: 0
}

@media(min-width:1200px) {
    .infinite__title--prev {
        left: 24px
    }
}

.infinite__title--prev:hover {
    color: #fff
}

.infinite__title--next {
    left: 100%
}

@media(min-width:1200px) {
    .infinite__title--next {
        left: calc(100% - 24px)
    }
}

.infinite__title--fade-in,
.infinite__title--next:hover {
    color: #fff
}

@media(max-width:767.98px) {
    .infinite__title--fade-in {
        font-size: 28px
    }
}

.infinite__link {
    display: block
}

.infinite__link,
.infinite__picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.infinite__picture {
    z-index: -1;
    display: flex
}

.infinite__picture--initial {
    transition: clip-path 1s;
    background-color: #0b0b0b;
    z-index: 50;
    clip-path: inset(0 0 0 0);
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: -1px;
    left: -1px
}

.infinite--initted .infinite__picture--initial {
    clip-path: inset(0 0 0 100%)
}

.infinite__picture-container {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    display: block;
    height: 100%;
    width: 100%
}

.infinite__picture-container:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(11, 11, 11, .2);
    pointer-events: none
}

.infinite__picture-container-wrapper {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-top: 66.667%
}

.icon-link {
    display: inline-flex;
    justify-content: center
}

.icon-link__text {
    margin-right: .2em
}

.icon-link__icon {
    transition: transform .3s cubic-bezier(.1, .25, .3, 1);
    max-width: 1em;
    max-height: 1em
}

.icon-link:hover .icon-link__icon {
    transform: translateX(.3em)
}

.jumplink {
    position: fixed;
    display: inline-block;
    z-index: 1000;
    bottom: 48px;
    right: 24px;
    width: 50px;
    height: 50px
}

@media(min-width:768px) {
    .jumplink {
        bottom: 24px;
        width: 100px;
        height: 100px
    }
}

.jumplink__icon {
    transition: transform .5s;
    transform: rotate(90deg)
}

.jumplink--up .jumplink__icon {
    transform: rotate(-90deg)
}

.footer {
    padding-bottom: 60px
}

@media(min-width:768px) {
    .footer {
        padding-bottom: 0
    }
}

.footer__navigation {
    width: 100%
}

.footer__navigation-link {
    margin-right: 12px
}

.footer__navigation-link:last-child {
    margin-right: 0
}
/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .modal-content {
    position: relative;
    width: 80%;
    max-width: 800px;
  }
  
  .modal-video {
    width: 100%;
    height: auto;
  }
  
  /* Close Button */
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
  }
  
  /* Showreel Video */
  .showreel__video {
    width: 100%;
    height: auto;
  }
  @keyframes slideInUp {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      color: rgba(255, 255, 255, 0.1);
    }
    to {
      color: rgb(248, 248, 248);
    }
  }
  
  .slide-top {
    animation: slideInUp 1s ease-out, fadeIn 1s ease-out;
  }
  