
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import 'aos/dist/aos.css';

@font-face {
  font-family: 'Apfel Grotezk Regular';
  src: url('../src/fonts/apfelgrotezk-regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Apfel Grotezk Regular BruKt';
  src: url('../src/fonts/apfelgrotezk-regularbrukt.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
video:hover {
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.animate-marquee {
  letter-spacing: 0.05em; 
  -webkit-text-stroke: 1px white; 
}
.button-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.custom-button {
  padding: 10px 20px;
  border: 2px solid white; 
  background-color: black; 
  color: white; 
  transition: all 0.3s ease;
  cursor: pointer;
  
}
.custom-button1 {
  padding: 10px 20px;
  border: 2px solid black; 
  background-color: white; 
  color: black; 
  transition: all 0.3s ease;
  cursor: pointer;
  
}
.custom-button:hover {
  background-color: white;
  color: black; 
  border: 2px solid black; 
}
.custom-button1:hover {
  background-color: #070636;
  color: white; 
  border: 2px solid white; 
}
.spaced-text span, .spaced-text {
  margin-right:0.5rem;
  margin-left: 0.5rem; 
  margin-top: 0.25rem;
}

.spaced-text {
  font-size: 1.25rem; 
}

.highlighted {
  background-color: #FFFFFF;
  color: black;
  padding: 0 5px;
  display: inline-block;
}

/* Mobil ve Tablet için */
@media (max-width: 1024px) {
  .relative {
    display: flex;
    justify-content: center; /* Yatayda ortalama */
    align-items: center;     /* Dikeyde ortalama */
   

  }

  .relative h1 {
    font-size: 3rem; /* Mobil ve tablet için yazı boyutunu küçült */
  }
}/* Mobil ve Tablet için */
@media (max-width: 1024px) {
  .relative {
    display: flex;
    justify-content: center; /* Yatayda ortalama */
    align-items: center;     /* Dikeyde ortalama */
    
  }

  .relative h1 {
    font-size: 3rem; /* Mobil ve tablet için yazı boyutunu küçült */
    padding-left: 5rem;
  }

  .relative.bg-yellow-300 {
    width: 80%; /* Kutu genişliği tam olmalı */
  }
}
