.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 oranı için */
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  